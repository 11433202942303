import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function Success(){
    
    const files = JSON.parse(localStorage.getItem("files"));



    return <Box sx={{ width: '100%', maxWidth: 500 }}>
     <Typography style={{backgroundColor:"#90EE90", padding: 10}} variant="h4" gutterBottom component="div">
    <CheckCircleIcon /> 
    <span> Success! </span>
    </Typography>
    <br/>
<Typography variant="subtitle1" gutterBottom component="div">
Information below was successfully sent:
</Typography>
<br/>

<Typography variant="body2" gutterBottom>
Email Address: {localStorage.getItem('email')}
      </Typography>
      <Typography variant="body2" gutterBottom>
      Case name / number: {localStorage.getItem('case_no')}
      </Typography>

      <Typography variant="body2" gutterBottom>
      Doctor: {localStorage.getItem('doctor')}
      </Typography>

      <Typography variant="body2" gutterBottom>
      Gender: {localStorage.getItem('gender')}
      </Typography>

      <Typography variant="body2" gutterBottom>
      Age: {localStorage.getItem('age')}
      </Typography>

      <Typography variant="body2" gutterBottom>
      Teeth Number: {localStorage.getItem('teeth_no')}
      </Typography>

      <Typography variant="body2" gutterBottom>
      Shade: {localStorage.getItem('shade')}
      </Typography>

      <Typography variant="body2" gutterBottom>
      Special Instructions: {localStorage.getItem('special')}
      </Typography>
<br/>
      <Typography variant="subtitle2" gutterBottom>
      Files Uploaded:
      </Typography>
      <ul>
      {files.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
    <br/>
    <br/>
    <br/>
    <Button variant="outlined" onClick={window.print} >
  Print
</Button>
        
</Box>
}

export default Success;