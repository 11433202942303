import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Form from './Form.js';
import Success from './Success.js';



function App() {
  document.title = "Digital Case Uploader";
  return (

    <Router>

      <div className="App">
        <div className="content">
          <Switch>
        
        <Route exact path="/"><Form/></Route>
        <Route path="/Success"><Success/></Route>
        </Switch>
        </div>
      </div>
    </Router>

  );
}

export default App;