import { useState } from "react";
import React from "react";
import S3 from "react-aws-s3";
import Button from "@mui/material/Button";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { DropzoneArea } from 'material-ui-dropzone';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import uploadLogo from './cdo_logo_uploader.png'


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




  const Form = () => {
    const history = useHistory();
    const [alertopen, setAlertOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
  let FileList = []
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [email_address, setEmail] = useState('');
  const [case_no, setCase] = useState('');
  const [doctor, setDoctor] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [teeth_no, setTeeth_no] = useState('');
  const [shade, setShade] = useState('');
  const [special, setSpecial] = useState('');

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertClose = (event, reason) => {
    setAlertOpen(false)}
  const handleToggle = () => {
    setOpen(!open);
  };
  const config = {
    bucketName: 'digital-case-uploads',
    region: 'ap-northeast-2',
    accessKeyId: 'AKIA42NNSRL4LIYZQBGF',
    secretAccessKey: '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'
  };
  
  const ReactS3Client = new S3(config);

  const handleClick = async (event) => {
    event.preventDefault();
    if (FileList.length === 0){
        console.log('no files')
        setAlertOpen(true);
        return false
    }
      setSuccess(false);
      setLoading(true);

      handleToggle()
    try {
      const newArr = [...FileList];
      const v = await Promise.all(
        newArr.map(async (file) => {
          let newFileName = file.name.toLowerCase();
          // assuming key is there in response
          const { key } = await ReactS3Client.uploadFile(file, newFileName);
          return {
            // newFileName,
            fileKey: key
          };
        })
      );
      console.log(v);
      setSuccess(true);
        setLoading(false);
        localStorage.setItem('email', email_address);
        localStorage.setItem('case_no', case_no);
        localStorage.setItem('doctor', doctor);
        localStorage.setItem('gender', gender);
        localStorage.setItem('age', age);
        localStorage.setItem('teeth_no', teeth_no);
        localStorage.setItem('shade', shade);
        localStorage.setItem('special', special);
      fetch("https://bdbt7mlgae.execute-api.ap-northeast-2.amazonaws.com/prod/send_email", {
     
        // Adding method type
        method: "POST",
         
        // Adding body or contents to send
        body: JSON.stringify({
            keys: v,
            email: email_address,
            case_no: case_no,
            doctor: doctor,
            gender: gender,
            age: age,
            teeth_no: teeth_no,
            shade: shade,
            special: special
        }),

         
        // Adding headers to the request
        headers: {
            "Content-type": "application/json"
        }
    })
    .then(url => url.json())
    .then(
        
        (url) => {
          localStorage.setItem('files', JSON.stringify(url.files));

            
            history.push('/Success');
            }
    );




    } catch (e) {
      console.error(e);
    }
  };

  
  return (
<div>
    <div className="logocontainer">
            <img style={{alignSelf: "center" }} src={uploadLogo} alt=""/>
        </div>

    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
           


    <div className="create">
      <h2>Digital Case Uploader</h2>
      <form className='upload-steps' onSubmit={handleClick}>


<TextField label="Email"
type="email" 
sx={{ m: 1, width: '41ch'}}
 variant="outlined"
 required
 value={email_address}
          onChange={(e) => setEmail(e.target.value)}
  />

<TextField
          label="Case name/number"
          sx={{ m: 1, width: '41ch'}}
          variant="outlined"
          value={case_no}
          required
          onChange={(e) => setCase(e.target.value)}
        />

<br/>
<br/>

<Divider textAlign="left">Notes:</Divider>
<br/>

<div   style={
    {
     border: '1px solid rgb(220,220,220)', width: '45ch', alighn: 'center', backgroundColor: "#ECE63D"
    }
  }>



<TextField
          // label="With normal TextField"
          sx={{ m: 1, width: '40ch', alighn: 'left' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">Doctor:</InputAdornment>,
          }}
          variant="standard"
          value={doctor}
          onChange={(e) => setDoctor(e.target.value)}
        />


<TextField
          // label="With normal TextField"
          sx={{ m: 1, width: '40ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">Gender:</InputAdornment>,
          }}
          variant="standard"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        />


<TextField
          // label="With normal TextField"
          sx={{ m: 1, width: '40ch'}}
          InputProps={{
            startAdornment: <InputAdornment position="start">Age:</InputAdornment>,
          }}
          variant="standard"
          
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
        <TextField
          // label="With normal TextField"
          sx={{ m: 1, width: '40ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">Teeth Numbers:</InputAdornment>,
          }}
          variant="standard"
          value={teeth_no}
          onChange={(e) => setTeeth_no(e.target.value)}
        />
          <TextField
          id="standard-start-adornment"
          sx={{ m: 1, width: '40ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">Shade:</InputAdornment>,
          }}
          variant="standard"
          value={shade}
          onChange={(e) => setShade(e.target.value)}
        />
          <TextField
          sx={{ m: 1, width: '40ch' }}
          label="Special instructions:"
          multiline
          variant="standard"
          value={special}
          onChange={(e) => setSpecial(e.target.value)}
        />

</div>
        <br/>
        <Snackbar open={alertopen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          Please upload files before clicking Submit!
        </Alert>
      </Snackbar>
    <DropzoneArea
    acceptedFiles={[".jpg", ".png", ".stl", ".STL", ".PNG", ".JPG"]}

    dropzoneText="Upload your Files here (only .STL, .JPG or .PNG)"
    onChange={(files) => {
      FileList = files
    }}
  multiple
  required
  filesLimit = {4}
  maxFileSize = {104857600}
  useChipsForPreview
  >
</DropzoneArea>

        <br />
        <Box sx={{ m: 1, position: 'relative' }}>
        <Button sx={buttonSx} type='submit' variant="contained" disabled={loading}>Submit</Button>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </Box>







      </form>
    </div>

    </Box>
    </div>
  );
}

 
export default Form;